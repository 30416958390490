import { render, staticRenderFns } from "./FriendItem.vue?vue&type=template&id=0e190499"
import script from "./FriendItem.vue?vue&type=script&lang=js"
export * from "./FriendItem.vue?vue&type=script&lang=js"
import style0 from "./FriendItem.vue?vue&type=style&index=0&id=0e190499&prod&scope=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-compiler@2.6.11_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports